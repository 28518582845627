import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { checkUserIsSuperAdmin } from '../../utils/user/checkAdmin/CheckAdmin';

import { M2MCredentials } from '../Users/M2MCredentials';
import { M2MCredentialsModal } from '../Users/M2MCredentialsModal';

import { AddM2MCredentials } from '../Users/AddM2MCredentials';
/**
 * Common component import
 */

export const M2MCredentialsContainer = ({
  user,
}: {
  user: any;
}): JSX.Element => {
  const connectedUser = useSelector(
    (state: RootState) => state.loginReducer.user
  );
  const isAuthenticated = useSelector(
    (state: RootState) => state.loginReducer.isAuthenticated
  );
  const hasAtLeatOneClientId =
    Array.isArray(user?.user_metadata?.clientIds) &&
    user?.user_metadata?.clientIds?.length > 0;
  const isSuperAdmin = checkUserIsSuperAdmin(isAuthenticated, connectedUser);
  const connectedUserIsCurrentUser = connectedUser.user_id === user.user_id;
  const displayApiCredentialsBlock =
    (connectedUserIsCurrentUser && hasAtLeatOneClientId) || isSuperAdmin;
  const displayApiCredentialsList = hasAtLeatOneClientId || isSuperAdmin;

  const [openDocumentationModal, setOpenDocumentationModal] = useState(false);

  const handleOpenDocumentationModal = () => {
    setOpenDocumentationModal(true);
  };

  const handleCloseDocumentationModal = () => {
    setOpenDocumentationModal(false);
  };

  if (!displayApiCredentialsBlock) return <></>;

  return (
    <ListItem>
      <ListItemText
        disableTypography
        primary={
          <div
            style={{
              flex: 1,
              justifyContent: 'center',
              alignContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1">API</Typography>
            <Link
              data-testid="download-documentation"
              component="button"
              variant="body1"
              onClick={handleOpenDocumentationModal}
              style={{
                paddingBottom: 2,
                marginTop: 5,
              }}
            >
              Get postman documentation
            </Link>
            <M2MCredentialsModal
              open={openDocumentationModal}
              onClose={handleCloseDocumentationModal}
            />
          </div>
        }
        secondary={
          displayApiCredentialsList && (
            <>
              <Typography
                variant="body2"
                style={{
                  color: 'rgba(0, 0, 0, 0.54)',
                  fontWeight: 400,
                  fontSize: '0.8125rem',
                }}
              >
                {displayApiCredentialsList &&
                  hasAtLeatOneClientId &&
                  user.user_metadata.clientIds.map((client_id: string) => {
                    return (
                      <M2MCredentials
                        key={client_id}
                        client_id={client_id}
                        user_id={user.user_id}
                      />
                    );
                  })}
                {!hasAtLeatOneClientId && (
                  <Typography
                    variant="body2"
                    style={{
                      color: 'rgba(0, 0, 0, 0.54)',
                      fontWeight: 400,
                      fontSize: '0.8125rem',
                    }}
                  >
                    No available API
                  </Typography>
                )}
              </Typography>
              <AddM2MCredentials user_id={user.user_id} />
            </>
          )
        }
      />
    </ListItem>
  );
};
