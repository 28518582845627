export type OperationTableCellPropsType = {
  value: string;
  statusCode?: number;
  status?: string;
  success?: boolean;
};
const OperationTableCell = ({
  value,
  statusCode,
  status,
  success,
}: OperationTableCellPropsType): JSX.Element => {
  let isInError = false;
  if (
    (statusCode && statusCode > 299) ||
    status?.toLocaleLowerCase() === 'error' ||
    success === false
  )
    isInError = true;
  return (
    <span
      style={{
        color: isInError ? 'rgb(194, 14, 14)' : '',
        cursor: 'pointer',
      }}
    >
      {value}
    </span>
  );
};

export default OperationTableCell;
