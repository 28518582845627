/* eslint-disable */

import { useEffect } from 'react';
import { useTheme, css, ClassNames } from '@emotion/react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Fab from '@mui/material/Fab';

import Card from '@mui/material/Card';
import { CardHeader, CardContent } from '@mui/material';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import BackButton from './commons/BackButton/BackButton';

import CreateIcon from '@mui/icons-material/Create';

import { capitalize } from '../utils/text';

import userIcon from '../assets/images/icons/default-user-avatar.png';

import Grid from '@mui/material/Grid';
import { withRouter } from '../utils/migrationHelper';
import { withTranslation } from 'react-i18next';

import { getUserStart } from '../actions';

import { checkUserIsSuperAdmin } from '../utils/user/checkAdmin/CheckAdmin';

import { M2MCredentialsContainer } from './Users/M2MCredentialsContainer';
/**
 * Common component import
 */

import CircularProgress from '@mui/material/CircularProgress';
import { checkUserHasRight } from 'utils/user/checkRights';
import { RightReferences } from 'enums/RightReferences';
import { CrudPermissions } from 'enums/CrudPermissions';
import { enums } from 'enums';
import { useUserLogo } from './Drawer/utils/logo';

let User = ({ isAuthenticated, user, currentUser, getUserStart, loading }) => {
  let navigate = useNavigate();
  let params = useParams();
  const classes = useTheme();
  const { userLogo } = useUserLogo(isAuthenticated, currentUser);

  useEffect(() => {
    async function initComponent() {
      if (isAuthenticated) {
        await getUserStart(params.userId);
      } else {
        navigate('/');
      }
    }
    initComponent();
  }, []);

  const formatUserRole = (role) => {
    return role === enums.UserRole.SUPER_ADMIN ? 'Admin' : 'Basic';
  };

  const renderUpdateButton = () => {
    let displayedUserOrganizationId =
      currentUser?.user_metadata?.organization?._id;
    let userOrganizationId = user?.user_metadata?.organization._id;

    let canUpdateDisplayedUser = false;
    canUpdateDisplayedUser =
      checkUserIsSuperAdmin(isAuthenticated, user) ||
      (checkUserHasRight(isAuthenticated, user, RightReferences.USERS, [
        CrudPermissions.UPDATE,
      ]) &&
        displayedUserOrganizationId.toString() ===
          userOrganizationId.toString());

    if (canUpdateDisplayedUser) {
      return (
        <Fab
          color="primary"
          aria-label="update"
          style={{
            right: 48,
            top: 85,
            left: 'auto',
            position: 'fixed',
          }}
          onClick={() => {
            navigate('/users/update/' + params.userId);
          }}
        >
          <CreateIcon />
        </Fab>
      );
    } else return false;
  };

  if (loading || !currentUser || !currentUser.app_metadata) {
    return (
      <ClassNames>
        {({ css }) => (
          <div className={css(classes.root)}>
            <CircularProgress
              size={24}
              className={css(classes.buttonProgress)}
            />
          </div>
        )}
      </ClassNames>
    );
  }

  let currentUserFullName =
    currentUser.user_metadata && currentUser.user_metadata.firstname
      ? capitalize(currentUser.user_metadata.firstname)
      : '';
  currentUserFullName +=
    currentUser.user_metadata && currentUser.user_metadata.lastname
      ? ' ' + capitalize(currentUser.user_metadata.lastname)
      : '';
  currentUserFullName +=
    currentUser.user_metadata && currentUser.user_metadata.username
      ? ' (aka ' + currentUser.user_metadata.username + ')'
      : '';

  let currentUserOganizationName =
    currentUser.user_metadata &&
    currentUser.user_metadata.organization &&
    currentUser.user_metadata.organization.name
      ? currentUser.user_metadata.organization.name
      : '';

  return (
    <>
      <BackButton />
      {renderUpdateButton()}
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Card className={css(classes.card)}>
            <CardHeader
              avatar={<img src={userIcon} style={{ maxHeight: 20 }} />}
              title="User information"
            />
            <CardContent>
              <Grid container spacing={10}>
                <Grid item xs={12}>
                  <div className={css(classes.demo)}>
                    <div style={{ textAlign: 'center' }}>
                      <img
                        src={userLogo}
                        style={{
                          height: 60,
                          width: 60,
                          borderRadius: '50%',
                          border: '1px solid rgba(0, 0, 0, 0.54)',
                          pointerEvents: 'none',
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = userIcon;
                        }}
                      />
                    </div>
                    <List>
                      <ListItem style={{ textAlign: 'center' }}>
                        <ListItemText
                          disableTypography
                          style={{ textAlign: 'center', paddingRight: 0 }}
                          primary={
                            <Typography variant="body1">
                              {currentUserFullName}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontWeight: 400,
                                fontSize: '0.8125rem',
                              }}
                            >
                              {currentUserOganizationName}
                            </Typography>
                          }
                        />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography variant="body1">Email</Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              style={{
                                color: 'rgba(0, 0, 0, 0.54)',
                                fontWeight: 400,
                                fontSize: '0.8125rem',
                              }}
                            >
                              {currentUser.email_verified
                                ? currentUser.email
                                : currentUser.email + ' (Not verified)'}
                            </Typography>
                          }
                        />
                      </ListItem>
                      {currentUser.user_metadata &&
                      currentUser.user_metadata.phoneNumber ? (
                        <ListItem>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography variant="body1">
                                Phone number
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="body2"
                                style={{
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontWeight: 400,
                                  fontSize: '0.8125rem',
                                }}
                              >
                                {currentUser.user_metadata.phoneNumber}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ) : (
                        <div></div>
                      )}
                      {currentUser.user_metadata &&
                      currentUser.user_metadata.number ? (
                        <ListItem>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography variant="body1">Address</Typography>
                            }
                            secondary={
                              <Typography
                                variant="body2"
                                style={{
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontWeight: 400,
                                  fontSize: '0.8125rem',
                                }}
                              >
                                {currentUser.user_metadata.number +
                                  ' ' +
                                  currentUser.user_metadata.street +
                                  ', ' +
                                  currentUser.user_metadata.zip +
                                  ' ' +
                                  currentUser.user_metadata.city +
                                  ', ' +
                                  currentUser.user_metadata.region +
                                  ' (' +
                                  currentUser.user_metadata.country +
                                  ')'}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ) : (
                        <div></div>
                      )}
                      {currentUser.app_metadata &&
                      currentUser.app_metadata.roles ? (
                        <ListItem>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography variant="body1">Role</Typography>
                            }
                            secondary={
                              <Typography
                                variant="body2"
                                style={{
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontWeight: 400,
                                  fontSize: '0.8125rem',
                                }}
                              >
                                {currentUser.app_metadata.roles.map((role) =>
                                  formatUserRole(role)
                                )}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ) : (
                        <div></div>
                      )}
                      {currentUser.user_metadata &&
                      currentUser.user_metadata.rights ? (
                        <ListItem>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography variant="body1">Rights</Typography>
                            }
                            secondary={
                              <Typography
                                variant="body2"
                                style={{
                                  color: 'rgba(0, 0, 0, 0.54)',
                                  fontWeight: 400,
                                  fontSize: '0.8125rem',
                                }}
                              >
                                {Array.isArray(
                                  currentUser?.user_metadata?.rights
                                ) &&
                                  currentUser.user_metadata.rights.map(
                                    (right) => {
                                      if (
                                        right.activePermissions &&
                                        Array.isArray(
                                          right.activePermissions
                                        ) &&
                                        right.activePermissions.length > 0
                                      ) {
                                        let permissionsList =
                                          right.activePermissions;
                                        return (
                                          ' ' +
                                          permissionsList +
                                          ' ' +
                                          (right.type.ref ===
                                          RightReferences.TRANSACTIONS
                                            ? 'audit trail'
                                            : right.type.ref) +
                                          ' | '
                                        );
                                      } else
                                        return (
                                          ' ' +
                                          (right.type.ref ===
                                          RightReferences.ADMINISTRATION
                                            ? 'administration'
                                            : right.type.ref) +
                                          ' | '
                                        );
                                    }
                                  )}
                              </Typography>
                            }
                          />
                        </ListItem>
                      ) : (
                        <div></div>
                      )}

                      <M2MCredentialsContainer user={currentUser} />
                    </List>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = ({ usersReducer, loginReducer, utilsReducer }) => {
  const { currentUser, loading } = usersReducer;
  const { unit } = utilsReducer;
  const { isAuthenticated, username, user } = loginReducer;
  return {
    currentUser,
    loading,
    isAuthenticated,
    username,
    user,
    unit,
  };
};

const mapDispatchToProps = {
  getUserStart,
};

User = withTranslation('translations')(User);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(User));
