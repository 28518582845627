/* eslint-disable */
import { useProduct } from './Redux/CustomHooks/useProduct';
import UpdateButton from './CustomComponents/Buttons/UpdateButton';
import AggregationCard from './CustomComponents/Card/AggregationCard/AggregationCard';
import useProductSlicesCombiner from './Redux/Slices/useProductSlicesCombiner';
import { UseProductSlicesCombinerType } from './Redux/CustomHooks/types';
import ProductReferenceFilesCard from 'components/ReferenceFiles/ProductReferenceFiles';
import { withRouter } from 'utils/migrationHelper';
import SummaryCard from './CustomComponents/Card/SummaryCard/SummaryCard';
import ChartCard from './CustomComponents/Card/ChartCard/ChartCard';
import LoadingCircle from 'components/commons/LoadingCircle';
import GenericGrid from 'components/GenericComponents/Grid/Grid/GenericGrid';
import BackButton from '../commons/BackButton/BackButton';
import { useProductAggregations } from './Redux/CustomHooks/useProductAggregations';

const Product = () => {
  const { currentProduct, loading }: UseProductSlicesCombinerType =
    useProductSlicesCombiner();
  useProductAggregations();
  useProduct();

  if (loading || !currentProduct || !currentProduct.displayedName)
    return <LoadingCircle size={24} />;

  return (
    <div>
      <BackButton />
      <UpdateButton />
      <GenericGrid isContainer spacing={2}>
        <SummaryCard />
        <ProductReferenceFilesCard
          userCanEditReferenceFiles={false}
          userCanReadReferenceFiles={true}
        />
        <ChartCard />
        <AggregationCard />
      </GenericGrid>
    </div>
  );
};

export default withRouter(Product);
