import { GridColDef } from '@mui/x-data-grid-pro';
import {
  getProductOrganizationName,
  getProductProductTypeName,
  getProductStandardName,
} from 'utils/datagridHelpers';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

import { CommodityTypes } from '../../../../enums/CommodityTypes';

const getProductsColumns = (isAuthenticated: boolean, user: any) => {
  const dataGridColumns: GridColDef[] = [
    {
      field: 'displayedName',
      headerName: 'Product',
      editable: false,
      width: 200,
    },
    {
      field: 'productId',
      headerName: 'Product Id',
      editable: false,
      width: 200,
    },
    {
      field: '_productStandardName',
      headerName: 'Standard',
      editable: false,
      width: 300,
      hide: !checkUserIsSuperAdmin(isAuthenticated, user),
      valueGetter: getProductStandardName,
    },
    {
      field: '_productTypeName',
      headerName: CommodityTypes.upperCase,
      editable: false,
      width: 300,
      hide: !checkUserIsSuperAdmin(isAuthenticated, user),
      valueGetter: getProductProductTypeName,
    },
    {
      field: '_organizationName',
      headerName: 'Organization',
      editable: false,
      width: 300,
      hide: !checkUserIsSuperAdmin(isAuthenticated, user),
      valueGetter: getProductOrganizationName,
    },
  ];
  return dataGridColumns;
};

export default getProductsColumns;
