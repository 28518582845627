import Fab from '@mui/material/Fab';
import RefreshIcon from '@mui/icons-material/Refresh';

import useOperation from 'components/Operations/Redux/CustomHooks/useOperation';
import useOperationsSlicesCombiner from 'components/Operations/Redux/Slices/useOperationsSlicesCombiner';
import PageTitle from '../../../commons/PageTitle';

import logsIcon from '../../../../assets/images/icons/transactions-list.png';

const RefreshButton = () => {
  const { refreshOperation } = useOperation();

  return (
    <Fab
      color="primary"
      aria-label="add"
      style={{
        right: 48,
        top: 85,
        left: 'auto',
        position: 'fixed',
      }}
      onClick={refreshOperation}
    >
      <RefreshIcon />
    </Fab>
  );
};

const OperationToolbar = () => {
  const { loading } = useOperationsSlicesCombiner();

  return (
    <>
      <PageTitle
        title={'Operation details'}
        loading={loading}
        image={logsIcon}
      />
      <RefreshButton />
    </>
  );
};

export default OperationToolbar;
