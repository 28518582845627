import { useEffect, useMemo } from 'react';
import AggregationCardContext from 'components/Product/Context/AggregationCardContext';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import AggregationContent from './AggregationContent/AggregationContent';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';
import {
  getBalanceProductAggregationsByPeriodStart,
  getOffchainProdSources,
} from 'actions';

const AggregationCard = () => {
  const dispatch: AppDispatch = useDispatch();
  const { productAggregations, prodSources, currentProduct } =
    useProductSlicesCombiner();
  //assetId is not displayed on balances page through query parameters
  //but only on product page
  const { assetId } = useParams();

  const productionNames = useMemo(
    () => (prodSources as any[]).map((source) => source.name),
    [prodSources]
  );
  const productionHistory =
    useMemo(() => {
      if (assetId) {
        const filteredResult = (productAggregations as any[]).filter(
          ({ source }) => productionNames.includes(source)
        );
        return filteredResult;
      }
      return;
    }, [productAggregations, productionNames, assetId]) ?? [];

  const dataHistory = useMemo(() => {
    if (assetId)
      return (productAggregations as any[]).filter(
        ({ source }: { source: string }) =>
          !productionNames.includes(source as string)
      );

    return (currentProduct as any)?.productBalanceAggregations?.filter(
      ({ source }: { source: string }) =>
        !productionNames.includes(source as string)
    );
  }, [productAggregations, productionNames, currentProduct]);

  useEffect(() => {
    if (!assetId && currentProduct && currentProduct.balancePeriod) {
      const [month, year] = currentProduct.balancePeriod.split('-').map(Number);
      dispatch(getOffchainProdSources('Production'));
      dispatch(
        getBalanceProductAggregationsByPeriodStart(
          currentProduct.productId,
          year,
          month
        )
      );
    }
  }, []);

  if (!productionHistory?.length && !dataHistory?.length) return <></>;

  return (
    <>
      {/* Production History Table */}
      {productionHistory.length > 0 && assetId && (
        <AggregationCardContext.Provider
          value={{ title: 'Production history', data: productionHistory }}
        >
          <AggregationContent />
        </AggregationCardContext.Provider>
      )}

      {/* Data History Table */}
      {dataHistory.length > 0 && (
        <AggregationCardContext.Provider
          value={{ title: 'Data history', data: dataHistory }}
        >
          <AggregationContent />
        </AggregationCardContext.Provider>
      )}
    </>
  );
};

export default AggregationCard;
