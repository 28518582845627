import databaseIcon from '../../../assets/images/icons/add-to-database.png';
import standardIcon from '../../../assets/images/icons/agreement-uploaded.png';
import cameraIcon from '../../../assets/images/icons/bullet-camera.png';
import businessReportIcon from '../../../assets/images/icons/business-report.png';
import calendar2Icon from '../../../assets/images/icons/calendar2.png';
import organizationsIcon from '../../../assets/images/icons/company.png';
import dateToIcon from '../../../assets/images/icons/date-to.png';
import listIcon from '../../../assets/images/icons/list.png';
import productIcon from '../../../assets/images/icons/product.png';
import productTypeIcon from '../../../assets/images/icons/productType.png';
import scheduleIcon from '../../../assets/images/icons/schedule.png';
import settingsIcon from '../../../assets/images/icons/settings.png';
import certificateIcon from '../../../assets/images/icons/standard.png';
import testsIcon from '../../../assets/images/icons/to-do2.png';
import logsIcon from '../../../assets/images/icons/transactions-list.png';
import transactionsIcon from '../../../assets/images/icons/transactions2.png';
import usersIcon from '../../../assets/images/icons/user-groups.png';
import walletIcon from '../../../assets/images/icons/wallet.png';
import wallet2Icon from '../../../assets/images/icons/wallet2.png';
import { enums } from '../../../enums/';
import { CommodityTypes } from '../../../enums/CommodityTypes';
import { LinkType } from '../types/LinkType';

const productTypesLabel = `${CommodityTypes.upperCasePlural}`;
const productTypesIcon = productTypeIcon;

const superVisionChildren = [
  {
    to: '/monitoring',
    icon: logsIcon,
    label: 'Monitoring',
  },
  {
    to: '/operations/feed',
    icon: logsIcon,
    label: 'Activity feed',
    onlyForRight: [],
    children: [],
  },
  {
    to: '/administration',
    icon: settingsIcon,
    label: 'Administration',
  },
];

if (process.env.REACT_APP_NODE_ENV === enums.EnvironmentTypes.DEVELOPMENT)
  superVisionChildren.push({
    to: '/tests',
    icon: testsIcon,
    label: 'Tests',
  });

export const superAdminLinks: LinkType[] = [
  //My Portfolio
  {
    to: '/balance',
    icon: walletIcon,
    label: 'My Portfolio',
    children: [
      {
        to: '/balances',
        icon: wallet2Icon,
        label: 'Balances',
      },
      {
        to: '/certificates',
        icon: certificateIcon,
        label: 'Proofs of retirement',
      },
      {
        to: '/transactions',
        icon: transactionsIcon,
        label: 'Transactions',
      },
    ],
  },
  {
    to: '/products',
    icon: databaseIcon,
    label: 'Reference data',
    children: [
      {
        to: '/products',
        icon: productIcon,
        label: 'Products',
      },
      {
        to: '/standards',
        icon: standardIcon,
        label: 'Standards',
      },
      {
        to: '/product-types',
        icon: productTypesIcon,
        label: productTypesLabel,
      },
      {
        to: '/lists',
        icon: listIcon,
        label: 'Lists',
      },
      {
        to: '/users',
        icon: usersIcon,
        label: 'Users',
      },
      {
        to: '/organizations',
        icon: organizationsIcon,
        label: 'Organizations',
      },
    ],
  },
  {
    to: '',
    icon: cameraIcon,
    label: 'Platform Supervision',
    children: superVisionChildren,
  },
  {
    to: '/operations',
    icon: calendar2Icon,
    label: 'Back Office Management',
    children: [
      {
        to: '/nominations',
        icon: scheduleIcon,
        label: 'Contracted volumes',
        hideInPlatinumUi: true,
      },
      {
        to: '/operations',
        icon: dateToIcon,
        label: 'Operations',
      },
      {
        to: '/reports',
        icon: businessReportIcon,
        label: 'Reports',
      },
    ],
  },
];
