import { useEffect, useState } from 'react';

import { getOperationsStart } from 'actions';
import {
  DEFAULT_PAGE_NUMBER,
  DEFAULT_PAGE_SIZE,
} from 'components/Transactions/utils/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from 'redux/store';

import useOperationsSlicesCombiner from '../Slices/useOperationsSlicesCombiner';

const useOperations = () => {
  const { isAuthenticated, user } = useOperationsSlicesCombiner();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  useEffect(() => {
    async function initComponent() {
      if (!isAuthenticated) {
        navigate('/');
      } else {
        await dispatch(getOperationsStart(page, pageSize));
      }
    }
    initComponent();
  }, [page, pageSize, dispatch, isAuthenticated, user, navigate]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const handlePageSizeChange = (size: number) => {
    setPageSize(size);
    setPage(0);
  };

  return { handlePageChange, handlePageSizeChange, page, pageSize };
};

export default useOperations;
