import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';
//import LaunchIcon from '@mui/icons-material/Launch';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

export const SnackbarOpenButton = ({
  path,
  snackbarKey,
  text = '',
}: {
  path: string;
  text: string;
  snackbarKey?: string;
}): JSX.Element => {
  const navigate = useNavigate();
  const { closeSnackbar } = useSnackbar();
  return (
    <>
      <span
        style={{ cursor: 'pointer', color: 'white' }}
        onClick={() => {
          navigate(path);
          closeSnackbar(snackbarKey);
        }}
      >
        {text}
      </span>
      <IconButton
        aria-label="dismiss"
        size="small"
        onClick={() => {
          closeSnackbar(snackbarKey);
        }}
        style={{ color: 'white', marginLeft: 10 }}
      >
        <HighlightOffIcon fontSize="inherit" />
      </IconButton>
    </>
  );
};

//When a component can't import and use the SnackbarOpenButton component directly (.ts not accepting jsx content, unlike .tsx),
//In this case, use the getOpenSnackbarButton function:
export const getOpenSnackbarButton = ({
  path,
  snackbarKey,
  text = '',
}: {
  path: string;
  text: string;
  snackbarKey?: string;
}): JSX.Element => {
  return (
    <SnackbarOpenButton path={path} snackbarKey={snackbarKey} text={text} />
  );
};
