import { closeActionDialog } from 'actions';
import GenericAutocompleteField from 'components/Administration/Generic/Form/Fields/Autocomplete/GenericAutocompleteField';
import GenericNumericField from 'components/Administration/Generic/Form/Fields/Numeric/GenericNumericField';
import GenericTextField from 'components/Administration/Generic/Form/Fields/Text/GenericTextField';
import GenericError from 'components/Administration/Generic/Form/Labels/GenericError';
import {
  getMonthsMenu,
  getYearsMenu,
} from 'components/Administration/Generic/Form/utils/utils';
import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';
import { FormikValues, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';
import { halfYearArray } from '../../../../Upload/utils/constants';
import useProductsAndOrganizationsOptions from '../../../../utils/CustomHooks/useProductsAndOrganizationsOptions';
import useDisplayVolumeErrorTypes from '../CustomHooks/useDisplayVolumeErrorTypes';
import useListenersHandlers from '../CustomHooks/useListenersHandlers';
import useUnitInForm from '../CustomHooks/useUnitInForm';
import { quantityTypeUppercase } from '../utils/constants';
import GenericCheckbox from 'components/Administration/Generic/Form/Fields/Checkbox/GenericCheckbox';
import useSetupFormikFields from 'components/DynamicList/utils/CustomHooks/useSetupFormikFields';
import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';
import { checkUserIsSuperAdmin } from 'utils/user/checkAdmin/CheckAdmin';

const useIssueCertificatesFormModalConfig = () => {
  const { t } = useTranslation();
  const { setFieldValue, submitForm, values } =
    useFormikContext<FormikValues>();
  const { unitInForm } = useUnitInForm();
  const { activeProductsOptions } = useProductsAndOrganizationsOptions();
  const { isForcedIssue } = useSetupFormikFields(values);
  const { user, isAuthenticated } = useLoginSlices();
  const {
    volumeFieldError,
    displayVolumeField,
    displayVolumeFieldError,
    isIssueCertificateButtonDisabled,
  } = useDisplayVolumeErrorTypes();

  const {
    handleIssueCertificateOriginChange,
    handleIssueCertificateYearChange,
    handleIssueCertificateMonthChange,
  } = useListenersHandlers();

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Cancel',
    submitLabel: 'Issue',
    onCancelHandler: closeActionDialog,
    onSubmitHandler: submitForm,
    disabled: isIssueCertificateButtonDisabled || displayVolumeFieldError,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericAutocompleteField,
        name: 'origin',
        label: 'Product',
        options: activeProductsOptions,
        selectedValue:
          activeProductsOptions.find(
            (option: any) => option.value === values.origin
          ) || '',
        onChange: handleIssueCertificateOriginChange,
        showErrors: false,
      },
      {
        component: GenericTextField,
        name: 'year',
        label: 'Year',
        select: true,
        dynamicMenuItems: true,
        menuItemsCallback: getYearsMenu,
        onChange: handleIssueCertificateYearChange,
        showErrors: false,
      },
      {
        component: GenericTextField,
        name: 'month',
        label: 'Period',
        select: true,
        dynamicMenuItems: true,
        menuItemsCallback: getMonthsMenu,
        condition: () => Boolean(!displayPlatinumUI),
        onChange: handleIssueCertificateMonthChange,
        showErrors: false,
      },
      {
        component: GenericTextField,
        name: 'month',
        label: 'Half year',
        select: true,
        menuItems: halfYearArray,
        condition: () => Boolean(displayPlatinumUI),
        onChange: handleIssueCertificateMonthChange,
        showErrors: false,
      },
      {
        component: GenericCheckbox,
        name: 'isForcedIssue',
        label: 'Force using latest data',
        checked: isForcedIssue,
        condition: () =>
          Boolean(checkUserIsSuperAdmin(isAuthenticated, user)) &&
          Boolean(displayVolumeField),
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('isForcedIssue', event.target.checked);
        },
      },
      {
        component: GenericNumericField,
        name: 'volume',
        label: t(quantityTypeUppercase + ' (' + unitInForm + ')'),
        condition: () => Boolean(displayVolumeField),
        onValueChange: (val: any) => {
          setFieldValue('volume', val.floatValue);
        },
        showErrors: false,
        value: values.volume,
      },
      {
        component: GenericError,
        error: volumeFieldError,
        displayError: displayVolumeFieldError,
      },
    ],
  ];
  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useIssueCertificatesFormModalConfig;
