import successIcon from '../../../../../assets/images/icons/checkmark.png';
import errorIcon from '../../../../../assets/images/icons/delete.png';

export type OperationTableStatusCellPropsType = {
  status: string;
};
const OperationTableStatusCell = ({
  status,
}: OperationTableStatusCellPropsType): JSX.Element => {
  const isInError = status === 'Error' ? true : false;
  return (
    <span
      style={{
        cursor: 'pointer',
      }}
    >
      {isInError && (
        <img
          src={errorIcon}
          alt={`error`}
          title={`error`}
          style={{ width: 22, height: 22 }}
        />
      )}
      {!isInError && (
        <img
          src={successIcon}
          alt={`ok`}
          title={`ok`}
          style={{ width: 22, height: 22 }}
        />
      )}
    </span>
  );
};

export default OperationTableStatusCell;
