import { FormikValues } from 'formik';

import { quantityTypeUppercase } from '../../../../../../utils/utils';

export const getValidationSchema = (
  values: FormikValues,
  products: any[],
  volumeMin?: number,
  alreadyUploadedVolume?: number
) => {
  const errors: any = {};
  if (!values.origin) {
    errors.origin = 'Required';
  }
  const numberRegEx = /^\d*$/;

  const selectedProduct = (products as any[]).find(
    (p) => p._id === values.origin
  );
  if (selectedProduct && !selectedProduct?.source)
    errors.origin =
      'This product has no source, please update the product with a source in "Data aquisition set up"';

  if (!values.volume) {
    if (values.volume === 0 && values.type === 'quantity')
      errors.volume = quantityTypeUppercase + ' cannot be null';
    else errors.volume = 'Required';
  }
  //else if (!numberRegEx.test(String(values.volume).toLowerCase())) { errors.volume = 'Invalid number'; }
  else if (values.type === 'quantity' && isNaN(values.volume)) {
    errors.volume = 'Invalid number';
  } else if (
    values.type === 'quantity' &&
    volumeMin &&
    values.volume < volumeMin
  ) {
    errors.volume =
      quantityTypeUppercase +
      ' cannot be < to already issued volume (' +
      volumeMin +
      ')';
  }

  const isInIncrementalMode =
    alreadyUploadedVolume &&
    alreadyUploadedVolume > 0 &&
    !values.overwritePreviousValue;
  if (isInIncrementalMode && !values.volumeToIncrement) {
    if (values.volumeToIncrement === 0)
      errors.volumeToIncrement = quantityTypeUppercase + ' cannot be null';
    else errors.volumeToIncrement = 'Required';
  } else if (isInIncrementalMode && isNaN(values.volumeToIncrement)) {
    errors.volumeToIncrement = 'Invalid number';
  }

  if (!values.year) {
    errors.year = 'Required';
  } else if (!numberRegEx.test(String(values.year).toLowerCase())) {
    errors.year = 'Invalid number';
  } else if (String(values.year).length != 4) {
    errors.year = 'Invalid year !';
  }
  if (!values.month) {
    errors.month = 'Required';
  } else if (!numberRegEx.test(String(values.month).toLowerCase())) {
    errors.month = 'Invalid number';
  } else if (String(values.month).length > 2) {
    errors.month = 'Invalid month format ! Must be MM';
  } else if (parseInt(values.month) < 1 || parseInt(values.month, 10) > 12) {
    errors.month = 'Invalid month ! Must be betweend [1, 12]';
  }

  if (!values.type) {
    errors.type = 'Required';
  } else if (values.type !== 'quantity') {
    const typeOfDataToUpload = values.type;
    const fullOrigin = (products as any[]).find(
      (product) => product._id === values.origin
    );
    const standardDynamicData = fullOrigin?.standard?.infoTexts.find(
      (data: any) =>
        data.sourceName === typeOfDataToUpload && data.type === 'dynamicData'
    );
    const standardDynamicDataUnit = standardDynamicData?.unit;
    if (!standardDynamicDataUnit) {
      errors.origin = `Standard dynamic data ${typeOfDataToUpload} is not correctly set for this product, missing unit`;
    } else if (
      standardDynamicDataUnit === '%' &&
      (values.volume > 100 || values.volume < 0)
    ) {
      errors.volume = `${typeOfDataToUpload} must be a %, between 0 and 100`;
    }
  }

  return errors;
};
