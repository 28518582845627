import { LinearProgress } from '@mui/material';
import {
  GridColDef,
  DataGridPro as Table,
  GridEventListener,
} from '@mui/x-data-grid-pro';
import LoadAll from 'components/commons/LoadAll/LoadAll';
import useOperations from 'components/Operations/Redux/CustomHooks/useOperations';
import useOperationslicesCombiner from 'components/Operations/Redux/Slices/useOperationsSlicesCombiner';
import { PAGINATION_MODE_SERVER_SIDE } from 'components/Transactions/utils/constants';
import { FILTER_MODE_CLIENT_SIDE } from 'components/Transactions/utils/constants';
import { useNavigate } from 'react-router-dom';

import OperationsToolbar from '../Toolbar/OperationsToolbar';

import GridToolbar from './CustomTableToolbar/GridToolbar';
import OperationsColumns from './utils/columns';

const OperationsTable = ({ inModal = true }: { inModal?: boolean }) => {
  const navigate = useNavigate();
  const { operations, allOperations, loading, totalCount } =
    useOperationslicesCombiner();

  const { handlePageChange, handlePageSizeChange, page, pageSize } =
    useOperations();

  const dataGridColumns: GridColDef[] = OperationsColumns();

  const handleOperationClick: GridEventListener<'rowClick'> = (params) => {
    if (!inModal) navigate('/operations/feed/' + params.row._id);
  };

  return (
    <div
      style={{
        height: document.documentElement.clientHeight - 200,
        paddingBottom: 100,
        margin: '10px 50px 0px',
      }}
      data-testid={'operations-table'}
    >
      <OperationsToolbar inModal={inModal} />
      <Table
        pagination
        loading={loading}
        paginationMode={PAGINATION_MODE_SERVER_SIDE}
        filterMode={FILTER_MODE_CLIENT_SIDE}
        rows={operations as any[]}
        columns={dataGridColumns}
        getRowId={(row) => row._id}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: GridToolbar,
          Footer: () => (
            <LoadAll
              onClick={() => {
                handlePageSizeChange(totalCount as number);
              }}
              loadedRows={(allOperations as any[]).length}
              totalRows={totalCount as number}
              typeOfData="operations"
            />
          ),
        }}
        onRowClick={handleOperationClick}
        page={page}
        pageSize={pageSize}
        rowCount={totalCount}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
      />
    </div>
  );
};

export default OperationsTable;
