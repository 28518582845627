import { CardContent, Grid } from '@mui/material';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
import 'react18-json-view/src/dark.css';
import GenericFieldLabel from 'components/GenericComponents/Grid/Field/FieldLabel/GenericFieldLabel';
import useOperation from 'components/Operations/Redux/CustomHooks/useOperation';
import SummaryField, {
  SummaryFieldPropsType,
} from '../SummaryField/SummaryField';

const OperationContent = () => {
  const { operation } = useOperation();
  if (!operation) return <></>;

  const operationFields = [
    {
      labelName: 'User',
      displayedValue: `${operation.user?.firstname} ${operation.user?.lastname}`,
    },
    {
      labelName: 'Operation Type',
      displayedValue: operation.type,
    },
    {
      labelName: 'Operation Date',
      displayedValue: operation.dateTime,
    },
    {
      labelName: 'Endpoint',
      displayedValue: operation.path,
    },
  ];
  if (operation.statusCode) {
    operationFields.push({
      labelName: 'Status code',
      displayedValue: operation.statusCode,
    });
  }

  return (
    <CardContent>
      <Grid container spacing={2}>
        {operationFields.map(
          ({ labelName, displayedValue }: SummaryFieldPropsType, index) => (
            <SummaryField
              key={index}
              labelName={labelName}
              displayedValue={displayedValue}
            />
          )
        )}
      </Grid>
      {operation.requestBody && (
        <Grid
          container
          spacing={2}
          style={{ marginTop: 2, padding: 20, justifyContent: 'flex-start' }}
        >
          <GenericFieldLabel label={'Request body'} />

          <JsonView
            dark={false}
            src={operation.requestBody}
            collapsed={true}
            style={{
              marginTop: 2,
              marginLeft: 20,
              justifyContent: 'flex-start',
            }}
          />
        </Grid>
      )}
    </CardContent>
  );
};

export default OperationContent;
