import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

const useOperationsSlices = () => {
  const { operations, loading, allOperations, totalCount, operation } =
    useSelector((state: RootState) => state.operationsReducer);

  return {
    operations,
    operation,
    loading,
    allOperations,
    totalCount,
  };
};

export default useOperationsSlices;
