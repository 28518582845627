import { useEffect, useRef } from 'react';

import { displayWarningInIssueDialog } from 'actions';
import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { displayPlatinumUI } from 'components/CreateOrUpdateProduct/utils/utils';
import { Formik, FormikProps, FormikValues } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import issueIcon from '../../../../../../../../assets/images/icons/issue-certificate-green.png';

import useUnit from './CustomHooks/useUnit';
import ModalContentIssueCertificatesSecondForm from './Form/Content/ModalContentIssueCertificatesConfirmationForm';
import ModalContentIssueCertificatesFirstForm from './Form/Content/ModalContentIssueCertificatesForm';
import ModalFooterIssueCertificatesSecondInBulkForm from './Form/Footer/ModalFooterIssueCertificatesConfirmationForm';
import ModalFooterIssueCertificatesForm from './Form/Footer/ModalFooterIssueCertificatesForm';
import { getValidationSchema } from './Form/FormValidation/validationForm';

const IssueCertificateForm = () => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const {
    issueCertificateData,
    displayIssueCertificateFormInDialog,
    maxQuantityForProduct,
    products,
    resetIssueCertificateForm,
  } = useAdministrationSlicesCombiner();
  const formikRef = useRef<FormikProps<any>>(null);

  const { unit } = useUnit();

  const initialValues = {
    origin:
      issueCertificateData &&
      issueCertificateData._origin &&
      issueCertificateData._origin._id
        ? issueCertificateData._origin._id
        : '',
    volume: issueCertificateData._volume,
    year: issueCertificateData._year || parseInt(moment().format('YYYY')),
    month: displayPlatinumUI
      ? '01'
      : issueCertificateData._month || moment().format('MM'),
    isForcedIssue: false,
  };

  useEffect(() => {
    if (resetIssueCertificateForm) {
      formikRef.current?.resetForm({
        values: {
          origin: '',
          volume: '',
          year: '',
          month: '',
          isForcedIssue: false,
        },
      });
    }
  }, [resetIssueCertificateForm, formikRef]);

  const onSubmitHandler = (values: FormikValues) => {
    const fullOrigin = (products as any[]).find(
      (product) => product._id === values.origin
    );
    const currentIssueCertificateData = {
      ...values,
      origin: fullOrigin,
    };
    dispatch(displayWarningInIssueDialog(currentIssueCertificateData));
  };

  return (
    <>
      <GenericModalTitle
        id="responsive-dialog-title"
        title={t('Issue balance')}
        icon={issueIcon}
      />
      {displayIssueCertificateFormInDialog && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmitHandler}
          validate={(values: FormikValues) =>
            getValidationSchema(
              values,
              products as any[],
              maxQuantityForProduct,
              unit
            )
          }
        >
          {() => {
            return (
              <div>
                <ModalContentIssueCertificatesFirstForm />
                <ModalFooterIssueCertificatesForm />
              </div>
            );
          }}
        </Formik>
      )}
      {!displayIssueCertificateFormInDialog && (
        <div>
          <ModalContentIssueCertificatesSecondForm />
          <ModalFooterIssueCertificatesSecondInBulkForm />
        </div>
      )}
    </>
  );
};

export default IssueCertificateForm;
