import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import { getDocumentation, openErrorSnack } from '../../actions';
import importJsonDocumentationButtonImage from '../../assets/images/documentation/import-button.png';
import dropOrSelectFileDocumentationButtonImage from '../../assets/images/documentation/drop-select-file-modal.png';
import viewFullDocumentationImage from '../../assets/images/documentation/view-full-documentation.png';

export function M2MCredentialsModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const fetchDocumentation = async () => {
    try {
      dispatch(getDocumentation());
    } catch (error) {
      console.error(error);
      dispatch(openErrorSnack('Error while fetching json api documentation.'));
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      maxWidth={'lg'}
    >
      <DialogTitle id="responsive-dialog-title">{'Installation'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          First step is to install this documentation in your own Postman
          account. Here is the process :
          <ul>
            <li>
              Download the documentation file (json){' '}
              <Link
                data-testid="download-documentation"
                component="button"
                variant="body1"
                onClick={fetchDocumentation}
                style={{ textDecoration: 'none', paddingBottom: 1 }}
              >
                Click here
              </Link>
            </li>
            <li>Open Postman and authenticate to your account if needed</li>
            <li>Select your workspace then click on import</li>
            <img
              src={importJsonDocumentationButtonImage}
              style={styles.imgStyle}
              alt="Import JSON button in Postman"
            />
            <li>Select the downloaded file in your desktop</li>
            <img
              src={dropOrSelectFileDocumentationButtonImage}
              style={styles.imgStyle}
              alt="Drop or select file modal in Postman"
            />
            <li>Click on the collection name</li>
            <li>Click on &quot;View complete documentation&quot;</li>
            <img
              src={viewFullDocumentationImage}
              style={styles.imgStyle}
              alt="View all documentation"
            />
          </ul>
          Then you just have to follow the instructions
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = {
  imgStyle: {
    width: '80%',
    marginTop: 30,
    marginBottom: 50,
    paddingLeft: 10,
    paddingRight: 10,
  },
};
