import GenericModalTitle from 'components/Administration/Generic/Modal/Title/GenericModalTitle';
import useOperationsSlicesCombiner from 'components/Operations/Redux/Slices/useOperationsSlicesCombiner';
import PageTitle from '../../../commons/PageTitle';

import dateToIcon from '../../../../assets/images/icons/date-to.png';
import logsIcon from '../../../../assets/images/icons/transactions-list.png';

const OperationsToolbar = ({ inModal = true }: { inModal?: boolean }) => {
  const { loading } = useOperationsSlicesCombiner();

  if (!inModal)
    return <PageTitle title={'User logs'} loading={loading} image={logsIcon} />;

  return (
    <GenericModalTitle
      title={'User logs'}
      icon={dateToIcon}
      loading={loading}
    />
  );
};

export default OperationsToolbar;
