/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
import DynamicListForm from 'components/DynamicList/DynamicListForm';
import DynamicLists from 'components/DynamicList/DynamicLists';
import CreateFormStandard from 'components/FormStandards/CRUD/Create/CreateFormStandard';
import UpdateFormStandard from 'components/FormStandards/CRUD/Update/UpdateFormStandard';
import Monitoring from 'components/Monitoring/Monitoring';
import ActivityFeed from 'components/Operations/ActivityFeed';
import OperationDetails from 'components/Operations/OperationDetails';
import NewOperations from 'components/NewOperations/NewOperations';
import Product from 'components/Product/Product';
import { Route, Navigate, Routes } from 'react-router-dom';

import Administration from './components/Administration/Administration';
import ArchivedProducts from './components/ArchivedProducts/ArchivedProducts';
import AuthCallback from './components/AuthCallback';
import Balances from './components/Balances/Balances';
import CertificateTabs from './components/CertificateTabs';
import CreateOrUpdateProduct from './components/CreateOrUpdateProduct/CreateOrUpdateProduct';
import Nominations from './components/Nominations/Nominations';
import ArchivedOrganizationList from './components/Organization/ArchivedOrganizationList';
import Organization from './components/Organization/Organization';
import OrganizationForm from './components/Organization/OrganizationForm';
import OrganizationList from './components/Organization/OrganizationList';
import OrganizationProductsList from './components/Organization/OrganizationProductsList';
import OrganizationUsersList from './components/Organization/OrganizationUsersList';
import Products from './components/Products/Products';
import ProductTypeForm from './components/ProductTypeForm';
import ProductTypes from './components/ProductTypes';
import ReferenceFiles from './components/ReferenceFiles/ReferenceFiles';
import Reports from './components/Reports/Reports';
import Standards from './components/Standards/Standards';
import Tests from './components/Tests';
import Transactions from './components/Transactions/Transactions';
import User from './components/User';
import Users from './components/Users';
import FormUsers from './components/Users/FormUsers';

const PrivateRoute = ({ isAuthenticated, children }) => {
  if (isAuthenticated !== true) {
    return <Navigate to="/" replace />;
  }
  return children;
};

function AppRoutesList({ props }) {
  return (
    <Routes>
      <Route
        key="AuthCallback"
        exact
        path="/"
        props={props}
        element={<AuthCallback props={props} />}
      />
      <Route
        key="AuthCallback"
        exact
        path="/get-user-on-authentication"
        props={props}
        element={<AuthCallback props={props} />}
      />
      <Route
        key="CertificateTabs"
        exact
        path="/certificates"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <CertificateTabs props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Transactions"
        exact
        path="/transactions"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Transactions props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Contracted volumes"
        exact
        path="/nominations"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Nominations props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Balances"
        exact
        path="/balances"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Balances props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Admin"
        exact
        path="/administration"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Administration props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Reports"
        exact
        path="/reports"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Reports props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Reference documents"
        exact
        path="/reports/reference-documents"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <ReferenceFiles props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Tests"
        exact
        path="/tests"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Tests props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Products"
        exact
        path="/products"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Products props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="CreateOrUpdateProduct"
        exact
        path="/products/create"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <CreateOrUpdateProduct props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="FormProductUpdate"
        exact
        path="/products/update/:assetId"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <CreateOrUpdateProduct props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Product"
        exact
        path="/products/read/:assetId"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Product props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="ProductTypes"
        exact
        path="/product-types"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <ProductTypes props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="ProductTypeForm"
        exact
        path="/product-types/create"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <ProductTypeForm props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="ProductTypeFormUpdate"
        exact
        path="/product-types/update/:productTypeId"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <ProductTypeForm props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="DynamicLists"
        exact
        path="/lists"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <DynamicLists props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="DynamicListForm"
        exact
        path="/lists/create"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <DynamicListForm props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="DynamicListFormUpdate"
        exact
        path="/lists/update/:dynamicListId"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <DynamicListForm props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Standards"
        exact
        path="/standards"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Standards props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="FormStandard"
        exact
        path="/standards/create"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <CreateFormStandard props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="FormStandardUpdate"
        exact
        path="/standards/update/:standardId"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <UpdateFormStandard props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="OrganizationList"
        exact
        path="/organizations"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <OrganizationList props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="ArchivedOrganizationList"
        exact
        path="/organizations/archived"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <ArchivedOrganizationList props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="ArchivedProducts"
        exact
        path="/products/archived"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <ArchivedProducts props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="OrganizationForm"
        exact
        path="/organizations/create"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <OrganizationForm props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="OrganizationFormUpdate"
        exact
        path="/organizations/update/:organizationId"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <OrganizationForm props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Organization"
        exact
        path="/organizations/read/:organizationId"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Organization props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="OrganizationUsersList"
        exact
        path="/organizations/read/:organizationId/users"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <OrganizationUsersList props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="OrganizationProductsList"
        exact
        path="/organizations/read/:organizationId/prod"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <OrganizationProductsList props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Users"
        exact
        path="/users"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Users props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="User"
        exact
        path="/users/read/:userId"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <User props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="FormUsers"
        exact
        path="/users/create"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <FormUsers props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="FormUsersUpdate"
        exact
        path="/users/update/:userId"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <FormUsers props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Operations"
        exact
        path="/operations"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <NewOperations props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="Monitoring"
        exact
        path="/monitoring"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <Monitoring props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="ActivityFeed"
        exact
        path="/operations/feed"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <ActivityFeed props={props} />
          </PrivateRoute>
        }
      />
      <Route
        key="OperationDetails"
        exact
        path="/operations/feed/:operationId"
        props={props}
        element={
          <PrivateRoute isAuthenticated={props.isAuthenticated}>
            <OperationDetails props={props} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default AppRoutesList;
