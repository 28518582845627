import { SyntheticEvent, useEffect, useState } from 'react';

import { closeActionDialog, sourceDocumentChanged } from 'actions';
import GenericAutocompleteField from 'components/Administration/Generic/Form/Fields/Autocomplete/GenericAutocompleteField';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';
import { FormikValues, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import GenericCheckbox from '../../../../../Fields/Checkbox/GenericCheckbox';
import GenericNumericField from '../../../../../Fields/Numeric/GenericNumericField';
import GenericTextField from '../../../../../Fields/Text/GenericTextField';
import GenericUploadField from '../../../../../Fields/Upload/GenericUploadField';
import GenericUploadLink from '../../../../../Fields/Upload/GenericUploadLink';
import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';
import { getMonthsMenu, getYearsMenu } from '../../../../../utils/utils';
import useProductsAndOrganizationsOptions from '../../../../utils/CustomHooks/useProductsAndOrganizationsOptions';
import { useSourceDocuments } from '../../../utils/CustomHooks/useLocalSourceDocument';
import useSetupFormikFields from '../../../utils/CustomHooks/useSetupFormikFields';
import { useTimezones } from '../../../utils/CustomHooks/useTimezones';

const useUploadDataFormModalConfig = () => {
  const { values, setFieldValue, errors, submitForm, setFieldTouched } =
    useFormikContext<FormikValues>();
  const dispatch: AppDispatch = useDispatch();
  const { timezones } = useTimezones();
  const {
    selectedOrigin,
    typesOfDataToUpload,
    quantityInputLabel,
    displayTypeOfDataSelector,
    quantityForIncrementationInputLabel,
    withOverwritePreviousValue,
    productVolume,
  } = useSetupFormikFields(values);
  const { selectedSourceDocument } = useSourceDocuments();
  const { products, localSourceDocument, loadingDataset } =
    useAdministrationSlicesCombiner();
  const { activeProductsOptions } = useProductsAndOrganizationsOptions();
  //modal components
  const modalHeader = {
    title: '',
  };

  //To reset the quantity fields when the dataset has finished loading we need a state variable
  const [resetQuantityInputs, setResetQuantityInputs] = useState<
    boolean | null
  >(null);

  //Check the loader allows to know when the dataset has finished loading and when to reset the quantity fields
  useEffect(() => {
    if (loadingDataset && resetQuantityInputs !== null)
      setResetQuantityInputs(null);
    else if (resetQuantityInputs === null && !loadingDataset)
      setResetQuantityInputs(true);
  }, [loadingDataset]);

  //If resetQuantityInputs is true, reset the quantity fields
  useEffect(() => {
    if (resetQuantityInputs) {
      //Avoid triggering the validation of the fields
      setFieldTouched('volumeToIncrement', false, false);
      setFieldValue('volumeToIncrement', 0);
      setFieldTouched('volume', false, false);
      setFieldValue('volume', 0);
      setResetQuantityInputs(null);
    }
  }, [resetQuantityInputs]);

  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Cancel',
    submitLabel: 'Upload dataset and source',
    onCancelHandler: closeActionDialog,
    onSubmitHandler: submitForm,
    disabled: JSON.stringify(errors) !== '{}' || loadingDataset,
    loading: loadingDataset,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericAutocompleteField,
        name: 'origin',
        label: 'Product',
        options: activeProductsOptions,
        selectedValue:
          activeProductsOptions.find(
            (option: any) => option.value === values.origin
          ) || '',
        onChange: (_: SyntheticEvent, selectedOption: any) => {
          setFieldValue('origin', selectedOption?.value);
          const p = (products as any[]).find(
            (product) => product._id === selectedOption?.value
          );
          const productTimezone = p?.timezone ? p.timezone : 'America/New_York';
          setFieldValue('timezone', productTimezone);

          //Reset the volume fields of the form
          setFieldValue('volume', 0);
          setFieldValue('volumeToIncrement', 0);
          setFieldValue('overwritePreviousValue', false);
          if (typesOfDataToUpload.length <= 1) {
            setFieldValue('type', 'quantity');
          }
        },
      },
      {
        component: GenericTextField,
        name: 'timezone',
        disabled: loadingDataset,
        label: 'Timezone',
        select: true,
        menuItems: timezones,
        condition: () => selectedOrigin && !selectedOrigin?.timezone,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          setFieldValue('timezone', e.target.value);
        },
      },
      {
        component: GenericTextField,
        name: 'type',
        disabled: loadingDataset,
        label: 'Type of data',
        select: true,
        menuItems: typesOfDataToUpload,
        condition: () => selectedOrigin && displayTypeOfDataSelector,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          //Reset the volume fields of the form
          setFieldValue('volume', 0);
          setFieldValue('volumeToIncrement', 0);
          setFieldValue('overwritePreviousValue', false);
          setFieldValue('type', e.target.value);
        },
      },
      {
        component: GenericTextField,
        name: 'year',
        disabled: loadingDataset,
        label: 'Year',
        select: true,
        dynamicMenuItems: true,
        menuItemsCallback: getYearsMenu,
        condition: () => selectedOrigin && Boolean(selectedOrigin),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          //Reset the volume fields of the form
          setFieldValue('volume', 0);
          setFieldValue('volumeToIncrement', 0);
          setFieldValue('overwritePreviousValue', false);
          setFieldValue('year', e.target.value);
        },
      },
      {
        component: GenericTextField,
        name: 'month',
        disabled: loadingDataset,
        label: 'Period',
        select: true,
        dynamicMenuItems: true,
        menuItemsCallback: getMonthsMenu,
        condition: () => selectedOrigin && Boolean(selectedOrigin),
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
          //Reset the volume fields of the form
          setFieldValue('volume', 0);
          setFieldValue('volumeToIncrement', 0);
          setFieldValue('overwritePreviousValue', false);
          setFieldValue('month', e.target.value);
        },
      },
      {
        component: GenericCheckbox,
        name: 'overwritePreviousValue',
        label: 'Overwrite uploaded volume',
        checked: withOverwritePreviousValue,
        condition: () =>
          selectedOrigin &&
          Boolean(selectedOrigin) &&
          (productVolume as number) > 0,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          if (!event.target.checked) {
            //We don't overwrite, we increment
            setFieldValue('volume', productVolume);
          } else {
            //We overwrite, we reset the volume
            setFieldValue('volume', 0);
          }
          setFieldValue('volumeToIncrement', 0);
          setFieldValue('overwritePreviousValue', event.target.checked);
        },
      },
      {
        component: GenericNumericField,
        name: 'volumeToIncrement',
        label: quantityForIncrementationInputLabel,
        value: values.volumeToIncrement,
        condition: () =>
          selectedOrigin &&
          Boolean(selectedOrigin) &&
          !withOverwritePreviousValue &&
          (productVolume as number) > 0,
        onValueChange: (val: any) => {
          setFieldValue('volume', val.floatValue + productVolume);
          setFieldValue('volumeToIncrement', val.floatValue);
        },
      },
      {
        component:
          values.type === 'quantity' ? GenericNumericField : GenericTextField,
        name: 'volume',
        value: values.volume,
        label: quantityInputLabel,
        condition: () =>
          selectedOrigin &&
          Boolean(selectedOrigin) &&
          (withOverwritePreviousValue || !((productVolume as number) > 0)),
        onValueChange: (val: any) => {
          setFieldValue('volume', val.floatValue);
        },
        onChange: (val: any) => {
          setFieldValue('volume', val.target.value);
        },
      },
      {
        component: GenericUploadField,
        condition: () => selectedOrigin && Boolean(selectedOrigin),
        onChange: (e: any) => {
          dispatch(sourceDocumentChanged(e.target.files && e.target.files[0]));
          e.target.value = null;
        },
      },
      {
        component: GenericUploadLink,
        condition: () => selectedOrigin && Boolean(selectedOrigin),
        noDocumentFoundLabel: 'Upload proof of production or source document ',
        documentToUploadRef: selectedSourceDocument,
        documentToUpload: localSourceDocument,
      },
    ],
  ];

  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useUploadDataFormModalConfig;
