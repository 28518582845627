import useLoginSlices from 'components/Balances/Redux/Slices/useLoginSlices';
import useUtilsSlices from 'components/Balances/Redux/Slices/useUtilsSlices';

import { UseProductSlicesCombinerType } from '../CustomHooks/types';

import useProductSlices from './useProductSlices';
import useProductsSlices from 'components/Products/Redux/Slices/useProductsSlices';

const useProductSlicesCombiner = (): UseProductSlicesCombinerType => {
  return {
    ...useProductSlices(),
    ...useLoginSlices(),
    ...useUtilsSlices(),
    ...useProductsSlices(),
  };
};

export default useProductSlicesCombiner;
