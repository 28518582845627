import { GridColDef } from '@mui/x-data-grid-pro';

import OperationTableCell from '../CustomCell/OperationTableCell';
import OperationTableStatusCell from '../CustomCell/OperationTableStatusCell';

const OperationStepsColumns = (): GridColDef[] => {
  const dataGridColumns: GridColDef[] = [
    {
      field: 'stepStatus',
      headerName: 'Status',
      editable: false,
      width: 60,
      filterable: true,
      sortable: true,
      valueGetter: (params) => {
        let isInError = false;
        if (
          params.row.statusCode > 299 ||
          params.row.status === 'error' ||
          params.row.success === false
        )
          isInError = true;
        return isInError ? 'Error' : 'Success';
      },
      renderCell: (params: any) => {
        return <OperationTableStatusCell status={params.value} />;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      editable: false,
      width: 400,
      filterable: true,
      sortable: true,
      renderCell: (params: any) => (
        <OperationTableCell
          value={params.row?.name}
          statusCode={params.row?.statusCode}
          status={params.row?.status}
          success={params.row?.success}
        />
      ),
    },
    {
      field: 'message',
      headerName: 'Message',
      editable: false,
      width: 400,
      filterable: true,
      sortable: true,
      renderCell: (params: any) => (
        <OperationTableCell
          value={params.row?.message}
          statusCode={params.row?.statusCode}
          status={params.row?.status}
          success={params.row?.success}
        />
      ),
    },
  ];

  return dataGridColumns;
};

export default OperationStepsColumns;
