import { LinearProgress } from '@mui/material';
import { GridColDef, DataGridPro as Table } from '@mui/x-data-grid-pro';
import useOperation from 'components/Operations/Redux/CustomHooks/useOperation';
import useOperationslicesCombiner from 'components/Operations/Redux/Slices/useOperationsSlicesCombiner';
import { PAGINATION_MODE_SERVER_SIDE } from 'components/Transactions/utils/constants';
import { FILTER_MODE_CLIENT_SIDE } from 'components/Transactions/utils/constants';

import OperationToolbar from './CustomComponents/Toolbar/OperationToolbar';
import OperationContent from './CustomComponents/Card/SummaryCard/OperationContent';

import GridToolbar from './CustomComponents/Table/CustomTableToolbar/GridToolbar';

import OperationStepsColumns from './CustomComponents/Table/utils/operationStepsColumns';

const OperationDetails = () => {
  const { loading } = useOperationslicesCombiner();

  const { operation } = useOperation();

  const dataGridColumns: GridColDef[] = OperationStepsColumns();

  return (
    <div
      style={{
        margin: '10px 50px 0px',
      }}
      data-testid={'operations-steps-table'}
    >
      <OperationToolbar />
      <OperationContent />
      {operation?.steps && operation?.steps.length > 0 && (
        <Table
          autoHeight
          pagination
          loading={loading}
          paginationMode={PAGINATION_MODE_SERVER_SIDE}
          filterMode={FILTER_MODE_CLIENT_SIDE}
          rows={operation.steps as any[]}
          columns={dataGridColumns}
          getRowId={(row) => row.id}
          components={{
            LoadingOverlay: LinearProgress,
            Toolbar: GridToolbar,
          }}
          rowCount={operation.steps.length}
          style={{ marginTop: 20 }}
        />
      )}
    </div>
  );
};

export default OperationDetails;
