/* eslint-disable */
import { AnyAction } from 'redux';
import { enums } from '../../enums';
import { DefaultProductType, ProductsReducerStateType } from './types';
import { v4 as uuidv4 } from 'uuid';

let displayPlatinumUI = process.env.REACT_APP_PRODUCT_CATEGORY === 'platinum';
let defaultUnitValue = displayPlatinumUI ? enums.Units.GRAMS : enums.Units.KWH;

let defaultProduct: DefaultProductType = {
  id: undefined,
  displayedName: undefined,
  productId: undefined,
  standard: null,
  deprecated: false,
  showCommentInput: false,
  deprecationComment: '',
  unit: defaultUnitValue,
  owner: '',
  smartMeter: '',
  active: true,
  organization: {},
  otherGreenAttributes: 'NA',
  typeOfPublicSupport: 'NA',
  sourceHistory: [],
  assetID: '',
  source: '',
  sourceID: '',
  periodNumber: 'NA',
  periodType: 'minute',
  wells: [],
  referenceFiles: [],
  isSourceFormVisible: false,
  balancePeriod: '',
  productBalanceAggregations: [],
};

for (let i = 0; i < 11; i++) {
  defaultProduct['info' + i] = '';
}

const INITIAL_STATE: ProductsReducerStateType = {
  currentProduct: defaultProduct,
  localProductPicture: null,
  localProductReferenceFiles: [],
  displayGoToAdministrationAfterAddingProductModal: false,
  graphDataProducer: {
    doughnutChartLabels: [],
    doughnutChartData: [],
    doughnutChartLastDayData: [],
    doughnutData: [],
    barChartData: [],
    barChartLabels: [],
  },
  allProducts: [],
  products: [],
  archivedProducts: [],
  error: { message: '', isVisible: false },
  loadingMeterRead: false,
  loading: false,
  loadingReferenceFiles: false,
  loadingReferenceFilesData: '',
  loadingForm: false,
  selected: [],
  selectedWells: [],
  page: 0,
  rowsPerPage: 10,
  productsFormError: undefined,
  prodSources: [],
  loadingProductAggregations: false,
  productAggregations: [],
  deletingFileIds: [],
  openAggregationDetails: false,
  assetsMeteringToDisplayInDialog: [],
  openConfirmReferenceFileDeletion: false,
  referenceFileToDelete: {
    _id: '',
    url: '',
  },
  totalCount: 0,
  totalCountAggregations: 0,
  referenceDocuments: [],
  loadingReferenceDocuments: false,
  openAggregationTransactionsDialog: false,
  openCheckProductAttributesDialog: false,
  openJSONDiffCheckProductDialog: false,
  productHistoryInJSON: [],
  s3LoadingURL: false,
  selectedCurrentProductVersionIndex: 1,
  selectedPreviousProductVersionIndex: 0,
};

const productsReducer = (
  state: ProductsReducerStateType = INITIAL_STATE,
  action: AnyAction
) => {
  switch (action.type) {
    case 'GET_PROD_SOURCES_START':
      return {
        ...state,
        loading: true,
      };

    case 'GET_PROD_SOURCES_SUCCESS':
      return {
        ...state,
        prodSources: action.payload,
        loading: false,
      };

    case 'GET_PROD_SOURCES_FAIL':
      return {
        ...state,
        loading: false,
      };
    case 'GET_PRODUCT_DATA_SUCCESS':
      return { ...state, graphDataProducer: action.payload, loading: false };
    //Products CRUD
    case 'CREATE_PRODUCT_START':
      return { ...state, loading: true };
    case 'CREATE_PRODUCT_SUCCESS':
      return { ...state, loading: false };
    case 'OPEN_GO_TO_ADMINISTRATION_MODAL_AFTER_ADDING_PRODUCT':
      return {
        ...state,
        displayGoToAdministrationAfterAddingProductModal: true,
      };
    case 'CLOSE_GO_TO_ADMINISTRATION_MODAL_AFTER_ADDING_PRODUCT':
      return {
        ...state,
        displayGoToAdministrationAfterAddingProductModal: false,
      };

    case 'CREATE_PRODUCT_FAIL':
      return { ...state, loading: false };
    case 'UPDATE_PRODUCT_START':
      return { ...state, loading: true };
    case 'UPLOAD_REFERENCE_FILES_STARTS':
      return { ...state, loadingReferenceFiles: true };
    case 'UPLOAD_REFERENCE_FILES_SUCCESS':
      return {
        ...state,
        loadingReferenceFiles: false,
        loadingReferenceFilesData: '',
      };
    case 'UPLOAD_REFERENCE_FILE':
      return { ...state, loadingReferenceFilesData: action.payload };

    case 'ARCHIVE_PRODUCT_START':
      return { ...state, loading: true };
    case 'DISPLAY_COMMENT_INPUT':
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          showCommentInput: true,
        },
      };
    case 'ARCHIVE_PRODUCT_SUCCESS':
      return {
        ...state,
        loading: false,
        currentProduct: {
          ...state.currentProduct,
          active: false,
        },
      };
    case 'ARCHIVE_PRODUCT_FAIL':
      return { ...state, loading: false };

    case 'RESTORE_PRODUCT_START':
      return { ...state, loading: true };
    case 'RESTORE_PRODUCT_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'RESTORE_PRODUCT_FAIL':
      return { ...state, loading: false };

    case 'UPDATE_PRODUCT_SUCCESS':
      return { ...state, loading: false };
    case 'UPDATE_PRODUCT_FAIL':
      return { ...state, loading: false };

    case 'DELETE_PRODUCT_START':
      return { ...state, loading: true };
    case 'DELETE_PRODUCT_SUCCESS':
      return { ...state, loading: false };
    case 'DELETE_PRODUCT_FAIL':
      return { ...state, loading: false };

    //Products data changing

    case 'PRODUCTS_FORM_ERROR':
      return {
        ...state,
        productsFormError: action.payload,
      };

    case 'ACTIVE_CHANGED':
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          active: action.payload,
        },
      };

    case 'PRODUCT_PICTURE_CHANGED':
      return {
        ...state,
        localProductPicture: action.payload,
      };

    case 'ADD_LOCAL_REFERENCE_FILES':
      let newLocalReferenceFilesWithAddedFiles = [
        ...(state.localProductReferenceFiles as any[]),
      ];
      const filesToAdd = action.payload;

      for (const file of filesToAdd) {
        let idForFileToAdd = uuidv4();
        newLocalReferenceFilesWithAddedFiles.push({ file, id: idForFileToAdd });
      }

      return {
        ...state,
        localProductReferenceFiles: newLocalReferenceFilesWithAddedFiles,
      };

    case 'REMOVE_LOCAL_REFERENCE_FILES':
      return {
        ...state,
        localProductReferenceFiles: [],
      };

    case 'REMOVE_LOCAL_REFERENCE_FILE':
      let newLocalReferenceFilesWithoutRemovedFile = [
        ...(state.localProductReferenceFiles as any[]),
      ];

      newLocalReferenceFilesWithoutRemovedFile =
        newLocalReferenceFilesWithoutRemovedFile.filter(
          (f) => f.id !== action.payload.localFileId
        );
      return {
        ...state,
        localProductReferenceFiles: newLocalReferenceFilesWithoutRemovedFile,
      };

    case 'SET_LOCAL_REFERENCE_FILE_LABEL':
      let newLocalReferenceFilesWithAddedLabel = [
        ...(state.localProductReferenceFiles as any[]),
      ];

      const { label, fileId: ___fileId } = action.payload;

      newLocalReferenceFilesWithAddedLabel =
        newLocalReferenceFilesWithAddedLabel.map((file) => {
          if (___fileId === file.id) {
            const fileWithNewLabel = new File([file.file], label, {
              type: file.file.type,
              lastModified: file.file.lastModified,
            });
            file.file = fileWithNewLabel;
            return file;
          }
          return file;
        });

      return {
        ...state,
        localProductReferenceFiles: newLocalReferenceFilesWithAddedLabel,
      };

    case 'SET_LOCAL_REFERENCE_FILE_NOTIFY':
      let newLocalReferenceFilesWithNotify = [
        ...(state.localProductReferenceFiles as any[]),
      ];

      const { notify, fileId: notifyfileId } = action.payload;

      newLocalReferenceFilesWithNotify = newLocalReferenceFilesWithNotify.map(
        (file) => {
          if (notifyfileId === file.id) file.notify = notify;
          return file;
        }
      );

      return {
        ...state,
        localProductReferenceFiles: newLocalReferenceFilesWithNotify,
      };

    case 'SET_LOCAL_REFERENCE_FILE_VALIDITY_START_DATE':
      let newLocalReferenceFilesWithAddedValidityStart = [
        ...(state.localProductReferenceFiles as any[]),
      ];

      const { validityStart, fileId: _fileId } = action.payload;

      newLocalReferenceFilesWithAddedValidityStart =
        newLocalReferenceFilesWithAddedValidityStart.map((file) => {
          if (_fileId === file.id) file.validityStart = validityStart;
          return file;
        });

      return {
        ...state,
        localProductReferenceFiles:
          newLocalReferenceFilesWithAddedValidityStart,
      };

    case 'SET_LOCAL_REFERENCE_FILE_VALIDITY_END_DATE':
      let newLocalReferenceFilesWithAddedValidityEnd = [
        ...(state.localProductReferenceFiles as any[]),
      ];

      const { validityEnd, fileId: __fileId } = action.payload;

      newLocalReferenceFilesWithAddedValidityEnd =
        newLocalReferenceFilesWithAddedValidityEnd.map((file) => {
          if (__fileId === file.id) file.validityEnd = validityEnd;
          return file;
        });

      return {
        ...state,
        localProductReferenceFiles: newLocalReferenceFilesWithAddedValidityEnd,
      };

    //Getting products
    case 'GET_REFERENCE_DOCUMENTS_START':
      return { ...state, loadingReferenceDocuments: true };
    case 'GET_REFERENCE_DOCUMENTS_SUCCESS':
      return {
        ...state,
        referenceDocuments: action.payload,
        loadingReferenceDocuments: false,
      };
    case 'GET_REFERENCE_DOCUMENTS_FAIL':
      return { ...state, loadingReferenceDocuments: false };
    case 'GET_PRODUCTS_START':
      return { ...state, loading: true };
    case 'GET_ARCHIVED_PRODUCTS_START':
      return { ...state, loading: true };
    case 'GET_PRODUCTS_SUCCESS':
      return {
        ...state,
        products: action.payload,
        allProducts: action.payload,
        loading: false,
      };
    case 'GET_ARCHIVED_PRODUCTS_SUCCESS':
      return { ...state, archivedProducts: action.payload, loading: false };
    case 'GET_PRODUCTS_FAIL':
      return { ...state, loading: false };
    case 'GET_ARCHIVED_PRODUCTS_FAIL':
      return { ...state, loading: false };
    case 'GET_PRODUCT_START':
      return { ...state, loading: true, selectedWells: [] };
    case 'GET_PRODUCT_SUCCESS':
      let selectedWells = action.payload?.wells?.map((well: any) => well?._id);
      return { ...state, currentProduct: action.payload, selectedWells };
    case 'GET_PRODUCT_FAIL':
      return { ...state, loading: false };

    case 'RESET_PRODUCT':
      return {
        ...state,
        currentProduct: defaultProduct,
        localProductPicture: null,
        localProductReferenceFiles: [],
      };
    case 'GET_PRODUCT_FORM_START':
      return { ...state, loadingForm: true };
    case 'GET_PRODUCT_FORM_SUCCESS':
      let selectedWellsInForm = action.payload?.wells?.map(
        (well: any) => well?._id
      );
      return {
        ...state,
        currentProduct: action.payload,
        loadingForm: false,
        selectedWells: selectedWellsInForm,
      };
    case 'GET_PRODUCT_FORM_FAIL':
      return { ...state, loadingForm: false };

    case 'SAVE_PRODUCING_SMART_METER_READ_START':
      return { ...state, loadingMeterRead: true };
    case 'SAVE_PRODUCING_SMART_METER_READ_SUCCESS':
      return { ...state, loadingMeterRead: false };
    case 'SAVE_PRODUCING_SMART_METER_READ_FAIL':
      return { ...state, loadingMeterRead: false };

    case 'LOGOUT_SUCCESS':
      return INITIAL_STATE;

    case 'SET_PRODUCT_WELLS':
      return { ...state, selectedWells: action.payload };

    // get product Production
    case 'GET_PRODUCT_PRODUCTION_START':
      return { ...state, loading: true };
    case 'GET_PRODUCT_PRODUCTION_SUCCESS':
      return { ...state, graphDataProducer: action.payload, loading: false };
    case 'GET_BALANCE_PRODUCT_AGGREGATIONS_BY_PERIOD_SUCCESS':
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          productBalanceAggregations: action.payload,
        },
        loading: false,
      };
    case 'GET_PRODUCT_PRODUCTION_FAIL':
      return { ...state, loading: false };

    // get product Aggregations
    case 'GET_PRODUCT_AGGREGATIONS_START':
      return { ...state, loadingProductAggregations: true };
    case 'GET_PRODUCT_AGGREGATIONS_SUCCESS':
      let productAggregations = action.payload.map((ag: any) => {
        ag.id = ag._id;
        return ag;
      });
      return {
        ...state,
        productAggregations,
        loadingProductAggregations: false,
      };
    case 'GET_PRODUCT_AGGREGATIONS_FAIL':
      return { ...state, loadingProductAggregations: false };

    case 'DISPLAY_CONFIRM_REFERENCE_FILE_DELETION_MODAL':
      let referenceFile = action.payload.referenceFile;
      return {
        ...state,
        openConfirmReferenceFileDeletion: true,
        referenceFileToDelete: referenceFile,
      };
    case 'HIDE_CONFIRM_REFERENCE_FILE_DELETION_MODAL':
      return {
        ...state,
        openConfirmReferenceFileDeletion: false,
      };

    case 'DISPLAY_UPLOAD_REFERENCE_FILE_MODAL':
      return {
        ...state,
        openUploadReferenceFileModal: true,
      };
    case 'HIDE_UPLOAD_REFERENCE_FILE_MODAL':
      return {
        ...state,
        openUploadReferenceFileModal: false,
      };

    case 'UPDATE_REFERENCE_FILES_SUCCESS':
      let updatedReferenceFiles = action.payload;

      let currentProductWithUpdatedReferenceFiles = {
        ...state.currentProduct,
      };
      currentProductWithUpdatedReferenceFiles.referenceFiles =
        updatedReferenceFiles;
      return {
        ...state,
        currentProduct: currentProductWithUpdatedReferenceFiles,
      };

    case 'DELETE_REFERENCE_FILE_START':
      let fileId = action.payload.fileId;
      let deletingFileIdsUpdated = [...(state.deletingFileIds as string[])];
      deletingFileIdsUpdated.indexOf(fileId) === -1
        ? deletingFileIdsUpdated.push(fileId)
        : console.log('This file is already deleting');

      return { ...state, deletingFileIds: deletingFileIdsUpdated };

    case 'DELETE_REFERENCE_FILE_SUCCESS':
      let fileIdToRemoveFromList = action.payload.fileId;

      let currentProductWithoutFileIdInReferenceFiles = {
        ...state.currentProduct,
      };
      currentProductWithoutFileIdInReferenceFiles.referenceFiles =
        currentProductWithoutFileIdInReferenceFiles?.referenceFiles?.filter(
          (refFile) => {
            return refFile._id !== fileIdToRemoveFromList;
          }
        );
      return {
        ...state,
        deletingFileIds: [...(state.deletingFileIds as string[])].filter(
          (f) => f !== fileIdToRemoveFromList
        ),
        currentProduct: currentProductWithoutFileIdInReferenceFiles,
      };

    case 'DELETE_REFERENCE_FILE_FAIL':
      let fileIdToRemoveBecauseOfFailure = action.payload.fileId;
      return {
        ...state,
        deletingFileIds: [...(state.deletingFileIds as string[])].filter(
          (f) => f !== fileIdToRemoveBecauseOfFailure
        ),
      };

    case 'SET_OPEN_AGGREGATION_DETAILS_DIALOG':
      return {
        ...state,
        openAggregationDetails: action.payload,
      };

    case 'SET_ASSETS_METERING_TO_DISPLAY_IN_DIALOG':
      return {
        ...state,
        assetsMeteringToDisplayInDialog: action.payload,
      };
    case 'DATA_ACQUISITON_TOGGLE_SET_SOURCE_FORM_VISIBLE':
      const previousSourceFormVisible =
        state.currentProduct?.isSourceFormVisible;

      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          isSourceFormVisible: !previousSourceFormVisible,
        },
      };

    case 'GET_PRODUCTS_TOTAL_COUNT_SUCCESS':
      return {
        ...state,
        totalCount: action.payload,
        loading: false,
      };

    case 'SET_PRODUCT_AGGREGATIONS_TOTAL_COUNT':
      return {
        ...state,
        totalCountAggregations: action.payload,
      };

    case 'CLOSE_ACTION_COMMENT_DIALOG':
      return {
        ...state,
        currentProduct: {
          ...state.currentProduct,
          showCommentInput: false,
        },
      };

    case 'DISPLAY_AGGREGATION_TRANSACTIONS_MODAL':
      return {
        ...state,
        openAggregationTransactionsDialog: true,
      };
    case 'HIDE_AGGREGATION_TRANSACTIONS_MODAL':
      return {
        ...state,
        openAggregationTransactionsDialog: false,
      };
    case 'DISPLAY_CHECK_PRODUCT_ATTRIBUTES_MODAL':
      return {
        ...state,
        openCheckProductAttributesDialog: true,
      };
    case 'DISPLAY_JSON_DIFF_CHECK_PRODUCT_ATTRIBUTES_MODAL':
      return {
        ...state,
        openJSONDiffCheckProductDialog: true,
      };
    case 'HIDE_CHECK_PRODUCT_ATTRIBUTES_MODAL':
      return {
        ...state,
        openCheckProductAttributesDialog: false,
      };
    case 'HIDE_JSON_DIFF_CHECK_PRODUCT_ATTRIBUTES_MODAL':
      return {
        ...state,
        openJSONDiffCheckProductDialog: false,
      };
    case 'SET_PRODUCT_JSON_HISTORY':
      return {
        ...state,
        productHistoryInJSON: action.payload,
      };
    case 'LOADING_PRODUCT_JSON_S3_URL_START':
      return {
        ...state,
        s3LoadingURL: true,
      };
    case 'LOADING_PRODUCT_JSON_S3_URL_SUCCESS':
      return {
        ...state,
        s3LoadingURL: false,
      };

    case 'SET_CURRENT_PRODUCT_JSON_VERSION':
      return {
        ...state,
        selectedCurrentProductVersionIndex: action.payload,
      };

    case 'SET_PREVIOUS_PRODUCT_JSON_VERSION':
      return {
        ...state,
        selectedPreviousProductVersionIndex: action.payload,
      };

    default:
      return state;
  }
};

export default productsReducer;
