import { forceIssueSnapshotCreation } from 'actions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

const useSetupFormikFields = (values: any) => {
  const dispatch: AppDispatch = useDispatch();
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [isForcedIssue, setIsForcedIssue] = useState<boolean>(false);

  useEffect(() => {
    setName(values?.name);
    setDescription(values?.description);
    setIsForcedIssue(values?.isForcedIssue);
    dispatch(forceIssueSnapshotCreation(values?.isForcedIssue));
  }, [values?.name, values?.description, values?.isForcedIssue]);

  return {
    name,
    description,
    isForcedIssue,
    setIsForcedIssue,
  };
};

export default useSetupFormikFields;
