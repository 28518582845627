import { useEffect } from 'react';

import { getOperationStart } from 'actions';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { AppDispatch } from 'redux/store';

import useOperationsSlicesCombiner from '../Slices/useOperationsSlicesCombiner';

const useOperation = () => {
  const { operationId } = useParams();
  const { isAuthenticated, user, operation } = useOperationsSlicesCombiner();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const refreshOperation = () => {
    if (operationId) dispatch(getOperationStart(operationId));
  };

  useEffect(() => {
    async function initComponent() {
      if (!isAuthenticated) {
        navigate('/');
      } else {
        if (operationId) await dispatch(getOperationStart(operationId));
      }
    }
    initComponent();
  }, [dispatch, isAuthenticated, user, navigate]);

  return { operation, refreshOperation };
};

export default useOperation;
