import {
  displayIssueCertificatesFormInDialog,
  issueCertificatesStart,
} from 'actions';
import GenericTypography from 'components/Administration/Generic/Form/Labels/GenericTypography';
import {
  addThousandsSeparatorToNumber,
  formatMonthYear,
  removeNonNumeric,
} from 'components/Administration/Generic/Form/utils/utils';
import useAdministrationSlicesCombiner from 'components/Administration/Redux/Slices/useAdministrationSlicesCombiner';

import { FormFieldsConfigType } from '../../../../../utils/types/formFieldsTypes';
import { FooterModalPropsType } from '../../../../../utils/types/types';
import useUnit from '../CustomHooks/useUnit';
import { quantityTypeUppercase } from '../utils/constants';

const useIssueCertificatesConfirmationModalConfig = () => {
  const { loadingIssueCertificate, issueCertificateData } =
    useAdministrationSlicesCombiner();

  const { unit } = useUnit();

  //modal components
  const modalHeader = {
    title: '',
  };
  const modalFooter: FooterModalPropsType = {
    cancelLabel: 'Back',
    submitLabel: 'Confirm',
    onCancelHandler: displayIssueCertificatesFormInDialog,
    onSubmitHandler: issueCertificatesStart(
      issueCertificateData._origin,
      issueCertificateData._year,
      issueCertificateData._month,
      issueCertificateData._volume,
      issueCertificateData.isForcedIssue
    ),
    loading: loadingIssueCertificate,
  };
  const formFieldsConfig: FormFieldsConfigType = [
    [
      {
        component: GenericTypography,
        name: 'Please check if the data are Ok before proceeding to the issue',
        styleGrid: { marginBottom: 10 },
        styleLabel: { paddingBottom: 10 },
        size: 12,
      },
      {
        component: GenericTypography,
        name: 'Product',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${issueCertificateData._origin.displayedName}`,
      },
      {
        component: GenericTypography,
        name: 'Period',
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${formatMonthYear(
          issueCertificateData._year + '-' + issueCertificateData._month
        )}`,
      },
      {
        component: GenericTypography,
        name: `${quantityTypeUppercase}`,
        styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
        details: `${addThousandsSeparatorToNumber(
          removeNonNumeric(issueCertificateData._volume)
        )} ${unit}`,
      },
      ...(issueCertificateData.isForcedIssue
        ? [
            {
              component: GenericTypography,
              name: 'Force using latest data',
              styleLabel: { color: 'rgba(0, 0, 0, 0.54)' },
              details: 'Yes',
            },
          ]
        : []),
    ],
  ];
  return {
    header: modalHeader,
    content: formFieldsConfig,
    footer: modalFooter,
  };
};

export default useIssueCertificatesConfirmationModalConfig;
