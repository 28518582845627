import { Dialog } from '@mui/material';
import {
  setAssetsMeteringToDisplayInDialog,
  setOpenAggregationDetails,
} from 'actions';
import ModalTitle from 'components/commons/ModalTitle/ModalTitle';
import useProductSlicesCombiner from 'components/Product/Redux/Slices/useProductSlicesCombiner';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'redux/store';

import eventsIcon from '../../../../../../../assets/images/icons/events.png';

import AssetsMeteringActions from './Actions/AssetsMeteringActions';
import AssetsMeteringContent from './Content/AssetsMeteringContent';

import { useRef, useEffect } from 'react';

const AssetsMeteringDialog = () => {
  const { openAggregationDetails } = useProductSlicesCombiner();
  const dispatch: AppDispatch = useDispatch();
  const dialogRef = useRef<HTMLDivElement>(null);

  const handleClose = () => {
    dispatch(setOpenAggregationDetails(false));
    dispatch(setAssetsMeteringToDisplayInDialog([]));
  };

  useEffect(() => {
    if (openAggregationDetails && dialogRef.current) {
      dialogRef.current.focus();
    }
  }, [openAggregationDetails]);

  return (
    <Dialog
      open={openAggregationDetails as boolean}
      onClose={handleClose}
      fullScreen={false}
      fullWidth={true}
      maxWidth="lg"
      disableAutoFocus={true}
      disableEnforceFocus={true}
      ref={dialogRef}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      }}
    >
      <ModalTitle
        id="responsive-dialog-title"
        title="Assets metering"
        handleClose={handleClose}
        icon={eventsIcon}
      />
      <AssetsMeteringContent />
      <AssetsMeteringActions />
    </Dialog>
  );
};

export default AssetsMeteringDialog;
